import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
    CacheRulesHeader,
    VadimHeader,
    mkHeader,
    shorteeHeader,
    YodaHeader,
    loaiHeader,
    CacheRulesVID,
    VadimVID,
    YodaVID,
    mkVID,
    shorteeVID,
    loaiVID,
    MixCopy } from '../assets/mixes';
import { useEffect, useState, useContext } from 'react'
import { RWebShare } from 'react-web-share';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import useMagic from '../components/hooks/useMagic';
import { AuthContext } from '../components/contexts/AuthContext';

// <Textfit mode="single" className="cache-arial-header text-uppercase text-justify" max={1000}>Cache Rules</Textfit> OLD TEXTFIT


function Mixes(props){
    //const { isAuthenticated, authenticating, isAuthUndefined } = useMoralis();
    const [isAuthenticated, setIsAuthenticated] = useContext(AuthContext);
    const navigate = useNavigate();
    const [ data, setData ] = useState();
    const [ vid, setVid ] = useState();
    const [ isLive, setIsLive] = useState(false);
    const [ header, setHeader ] = useState();
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
    const shareCacheCopy = "Share this Mix";
    const shareDropCopy = "Share this Drop";
    const shareOpensea = "View on Opensea"
    const magic = useMagic();

    useEffect(() => {
        if (!isAuthenticated){
            navigate("/");
        }
    }, [isAuthenticated]);

    useEffect(() => {
        switch(props.mixName){
            default : break;
            case 'CacheRules':
                setVid(CacheRulesVID);
                setHeader(CacheRulesHeader);
                setData(MixCopy.CacheRules);
                break;
            case 'Vadim':
                setVid(VadimVID);
                setHeader(VadimHeader);
                setData(MixCopy.Vadim);
                break;
            case 'Yoda':
                setVid(YodaVID);
                setHeader(YodaHeader);
                setData(MixCopy.Yoda);
                break;
            case 'mk':
                setVid(mkVID);
                setHeader(mkHeader);
                setData(MixCopy.mk);
                break;
            case 'shortee':
                setVid(shorteeVID);
                setHeader(shorteeHeader);
                setData(MixCopy.shortee);
                break;
            case 'loai':
                setVid(loaiVID);
                setHeader(loaiHeader);
                setData(MixCopy.LOAI);
                break;
        }
    }, [props.mixName]);

    return (
        <Container fluid className="p-0 m-0 w-100">
            <Image fluid className="img-full-wide" src={header} />
            {!isMobile ?
            <Container className="mt-5">
                <Row>
                    <Col sm={7} className="px-5 pt-5">
                            <video playsInline className="mix-vid mb-4" autoPlay loop src={vid} muted />
                            <p className="h6 mb-2 text-uppercase">Play the mix</p>
                            {props.mixName === "CacheRules" ? 
                            <>
                            <iframe width="100%" height="120" src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&hide_artwork=1&feed=%2Fcachemoneyclick%2Fcache-90s-demo-mixtape%2F" frameBorder="0" title="CACHE RULES MIX"></iframe>
                            <p className="h6 text-uppercase mt-4">Tracklisting</p>
                            {data?.tracklist.map((item, key) => {
                                return <p key={key} className="mb-0 cache-haptik text-capitalize">{item}</p>
                            })}
                            </>
                            :
                            <>
                            <p className="mt-1 cache-haptik">The ability to play this mix will be exclusive to the owner of this mixtape NFT.
                            {data?.playUrl && <>If you are the owner of the NFT, please <a href={data.playUrl}><b>click here to access the mix</b></a>. You will need to validate your wallet and ownership of the NFT to listen.
                            <br/><br/>
                            If you are interested in purchasing this mix please click the 'View on OpenSea' button to the right. 
                            </>}
                            <br/><br/>If you'd like to learn more about how this works, please check out our demo CACHE mixtape <Link to="/mixes/cache-rules-mix">here</Link>.</p>
                            {data?.utilities[0] && <p className="h6 mb-2 text-uppercase">Utilities</p>}
                            {data?.utilities.map((item, key) => {
                                return <p key={key} className="mb-0 cache-haptik">{item}</p>
                            })}
                            </>
                            }
                    </Col>
                    <Col sm={5} className="px-5 pt-5">
                            <p className="mt-3 h5 text-uppercase">{data?.artist}</p>
                            <p className="h6 mb-0 text-uppercase mt-2">{data?.title}</p>
                            <p className="h6 mb-0 text-uppercase mt-1">{data?.amount}</p>
                            <p className="h6 mb-0 text-uppercase mt-4">{data?.description[0]}</p>
                            <p className="mt-1 cache-haptik">{data?.description[1]}</p>
                            <p className="h6 mb-0 text-uppercase mt-4">{data?.description[2]}</p>
                            <p className="mt-1 cache-haptik">{data?.description[3]}</p>
                            <p className="h6 mb-0 text-uppercase mt-4">{data?.description[4]}</p>
                            <p className="mt-1 cache-haptik">{data?.description[5]}</p>
                            {props.mixName === "CacheRules" ? <></> : isLive ?
                            <p className="h6 mb-0 text-uppercase mt-4 red-link">This Auction is live now!</p>
                            : data?.url ? <p className="h6 mb-0 text-uppercase mt-4">Make an offer via Opensea</p> :
                            <p className="h6 mb-0 text-uppercase mt-4">NFT AUCTION IS COMING SOON</p>}
                            {props.mixName === "CacheRules" ? 
                            <RWebShare data={{
                                text: "Check out this 90s HipHop Mix!",
                                url: window.location.href,
                                title: "CACHE MIXTAPES"
                            }}>
                                <Button variant="dark" className="mt-4 text-uppercase w-100 border-0 rounded-0 fs-7 share-btn">{shareCacheCopy}</Button>
                            </RWebShare>
                            : data?.url ?
                            <a href={data.url}>
                            <Button variant="dark" className="mt-4 text-uppercase w-100 border-0 rounded-0 fs-7 share-btn">{shareOpensea}</Button>
                            </a>
                            : 
                            <RWebShare data={{
                                text: "Check out this 90s HipHop Mix!",
                                url: window.location.href,
                                title: "CACHE MIXTAPES"
                            }}>
                                <Button variant="dark" className="mt-4 text-uppercase w-100 border-0 rounded-0 fs-7 share-btn">{shareDropCopy}</Button>
                            </RWebShare>
                            }
                            <LinkContainer to="/FAQs">
                                <Button className="mt-2 button-link p-0 fs-8">Read our FAQs</Button>
                            </LinkContainer>
                            <LinkContainer to="/terms">
                                <Button className="mt-2 ms-2 button-link p-0 fs-8">Read Our T&Cs</Button>
                            </LinkContainer>
                    </Col>
                </Row>
            </Container>
            :
            <Container className="mt-3">
                <Row>
                    <Col className="mt-2">
                            <video playsInline className="mix-vid mb-2" autoPlay loop src={vid} muted />

                            <p className="mt-3 h5 text-uppercase">{data?.artist}</p>
                            <p className="h6 mb-0 text-uppercase mt-2">{data?.title}</p>
                            <p className="h6 mb-0 text-uppercase mt-2">{data?.amount}</p>
                            <p className="h6 mb-0 text-uppercase mt-3">{data?.description[0]}</p>
                            <p className="mt-1 cache-haptik">{data?.description[1]}</p>
                            <p className="h6 mb-0 text-uppercase mt-2">{data?.description[2]}</p>
                            <p className="mt-1 cache-haptik">{data?.description[3]}</p>
                            <p className="h6 mb-0 text-uppercase mt-2">{data?.description[4]}</p>
                            <p className="mt-1 cache-haptik">{data?.description[5]}</p>

                            <p className="h6 mt-3 mb-2 text-uppercase">Play the mix</p>
                            {props.mixName === "CacheRules" ? 
                            <>
                            <iframe width="100%" height="120" src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&hide_artwork=1&feed=%2Fcachemoneyclick%2Fcache-90s-demo-mixtape%2F" frameBorder="0" title="CACHE RULES MIX"></iframe>
                            <p className="h6 text-uppercase mt-4">Tracklisting</p>
                            {data?.tracklist.map((item, key) => {
                                return <p key={key} className="mb-0 cache-haptik text-capitalize">{item}</p>
                            })}
                            </>
                            :
                            <>
                            <p className="mt-1 cache-haptik">The ability to play this mix will be exclusive to the owner of this mixtape NFT.
                            {data?.playUrl && <>If you are the owner of the NFT, please <a href={data.playUrl}>click here</a> to access the mix. You will need to validate your wallet and ownership of the NFT to listen.
                            <br/><br/>
                            If you are interested in purchasing this mix please click the 'View on OpenSea' button to the right. 
                            </>}
                            <br/>If you'd like to learn more about how this works, please check out our demo CACHE mixtape <Link to="/mixes/cache-rules-mix">here</Link>.</p>
                            {data?.utilities[0] && <p className="h6 mb-2 text-uppercase">Utilities</p>}
                            {data?.utilities.map((item, key) => {
                                return <p key={key} className="mb-0 cache-haptik">{item}</p>
                            })}
                            </>
                            }
                            {props.mixName === "CacheRules" ? <></> : isLive ?
                            <p className="h6 mb-0 text-uppercase mt-4">This Auction is Live!</p>
                            :
                            <p className="h6 mb-0 text-uppercase mt-4">NFT DROP COMING SOON</p>}
                            {props.mixName === "CacheRules" ? 
                            <RWebShare data={{
                                text: "Check out this 90s HipHop Mix!",
                                url: window.location.href,
                                title: "CACHE MIXTAPES"
                            }}>
                                <Button variant="dark" className="mt-4 text-uppercase w-100 border-0 rounded-0 fs-7 share-btn">{shareCacheCopy}</Button>
                            </RWebShare>
                            : data?.url ?
                            <a href={data.url}>
                            <Button variant="dark" className="mt-4 text-uppercase w-100 border-0 rounded-0 fs-7 share-btn">{shareOpensea}</Button>
                            </a>
                            : 
                            <RWebShare data={{
                                text: "Check out this 90s HipHop Mix!",
                                url: window.location.href,
                                title: "CACHE MIXTAPES"
                            }}>
                                <Button variant="dark" className="mt-4 text-uppercase w-100 border-0 rounded-0 fs-7 share-btn">{shareDropCopy}</Button>
                            </RWebShare>
                            }
                        
                            <LinkContainer to="/FAQs">
                                <Button className="mt-2 button-link p-0 fs-8">Read our FAQs</Button>
                            </LinkContainer>
                            <LinkContainer to="/terms">
                                <Button className="mt-2 ms-2 button-link p-0 fs-8">Read Our T&Cs</Button>
                            </LinkContainer>

                    </Col>
                </Row>
            </Container>}
        </Container>
    );

}

export default Mixes;