import { Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';

function Privacy(){

    return (
        <Container fluid className="p-0 m-0 w-100">
            <Container className="mt-5">
                <Row>
                    <Col className="p-5">
                        <p className="mt-3 h6 text-uppercase">Privacy Policy</p>
                        <p className="mt-1 cache-haptik">LAST MODIFIED: 7th June 2022
                            <br/><br/>
                            Cache Holdings Ltd (CACHE, we or us) cares about the privacy of your personal information and is committed to protecting it. This Privacy Policy (Policy) explains what information we collect about you and why, what we do with that information, and how we handle that information.
                            <br/><br/>
                            For the purposes of the applicable data protection laws (the UK General Data Protection Regulation (GDPR), together with any implementing legislation, in particular, the Data Protection Act 2018), the company responsible for your personal information is CACHE. This Policy is incorporated into CACHE's Terms & Conditions (Terms & Conditions) and applies to information obtained by us through your use of the information, software, products and services made available through the cache.city website (Website).
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Collection of your personal information</p>
                        <p className="mt-1 cache-haptik">
                        CACHE collects information that, alone or in combination with other data, could be used to identify you (Personal Information), such as your email address, name, physical address, telephone number or blockchain wallet address. If you make a purchase from CACHE, we may collect and store your billing and credit card information. CACHE may also collect anonymous demographic information which is not unique to you, such as your postal code and preferences (Non-Personal Information). Non-Personal Information is not linked to your Personal Information (for example, your IP address).
                        <br/><br/>
                        CACHE will collect your Personal Information in a number of ways, including when you contact us through the Website, by telephone, post, email, by making a purchase from us or through the other means set out below.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Information you provide when you create an account</p>
                        <p className="mt-1 cache-haptik">When you register for a CACHE account, you voluntarily give us certain Personal Information, including your:</p>
                        <span className="cache-haptik">
                        <ul>
                            <li>name and email address.</li>
                            <li>blockchain wallet address.</li>
                            <li>billing information, if you make a purchase. This is required to complete a commercial transaction on the Website.</li>
                        </ul>
                        </span>
                        <p className="h6 mb-0 text-uppercase mt-4">Information from third parties</p>
                        <p className="mt-1 cache-haptik">
                        We collect Personal Information about you from third parties such as social media networks. We use this information to source and qualify potential customer leads.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Other information we collect</p>
                        <p className="mt-1 cache-haptik">
                        We also collect this Personal Information as you use the Website:                        
                        <br/><br/>
                        User Content. This consists of all text, documents, content or information uploaded, entered, or otherwise transmitted by you in connection with your use of the Website.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Automatically collected information</p>
                        <p className="mt-1 cache-haptik">
                        When you visit our Website, we automatically collect information about your computer hardware and software such as your IP address, browser type, domain names, access times, operating system, cookie information, referring website addresses, pages visited, links clicked, text entered, and Internet Service Provider. We analyse and use this information to better understand how our users use the service, to maintain and improve our service, and in some cases, to publicly disclose aggregated statistics regarding our services.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Use of your personal information</p>
                        <p className="mt-1 cache-haptik">CACHE collects and uses your Personal Information as necessary to perform our contract with you and for our legitimate business interests, including to:</p>
                        <span className="cache-haptik">
                        <ul>
                            <li>operate the Website and deliver the services.</li>
                            <li>communicate with you about the services you use, as well as respond to requests for assistance, including account verification support if you've having difficulty accessing your account.</li>
                            <li>display user content associated with your account and make sure it is available to you when you use our services.</li>
                            <li>understand and improve how our users use and interact with our services.</li>
                            <li>market our services to you.</li>
                        </ul>
                        When you register with us and set up an account to receive our services, the legal basis for this processing is the performance of a contract between you and us and/or taking steps, at your request, to enter into such a contract. When we process any payment made by you, we process your Personal Information to prevent or detect fraud (including fraudulent payments and fraudulent use of the Website). The legal basis for this processing is compliance with our legal obligations.
                        </span>
                        <p className="h6 mb-0 text-uppercase mt-4">Storage of personal information</p>
                        <p className="mt-1 cache-haptik">
                        Unless a longer retention period is required or permitted by law, we will only hold your Personal Information on our systems for the period necessary to fulfil the purposes outlined in this Policy, or until you request that the information be deleted in accordance with your right of erasure (see "Your rights" section).                  
                        <br/><br/>
                        Even if we delete your Personal Information, we reserve the right to maintain a copy for legal, tax or regulatory purposes, but in such an event, we will do so only as long as necessary to fulfil those legal, tax or regulatory purposes. Please contact <a href="mailto:support@cache.city">support@cache.city</a> for more information.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Personal information access and disclosure</p>
                        <p className="mt-1 cache-haptik">We will only disclose Personal Information to third parties:</p>
                        <span className="cache-haptik">
                        <ul>
                            <li>when we use vendors, lawyers and service providers to assist us in meeting business or operating needs, such as hosting our Website, communicating with users, delivering and improving our services, email communication, invoicing and payments, customer support services and analytics. These service providers may only access, process or store Personal Information pursuant to our instructions and to perform their duties to us, and in accordance with applicable laws and regulations; or</li>
                            <li>when we have your explicit consent to share your Personal Information; or</li>
                            <li>when we determine that disclosure is required to protect the rights, property, or personal safety of CACHE and users of the Website, or to respond to lawful subpoenas, warrants, or requests by public or regulatory authorities, including requests by law enforcement authorities; or</li>
                            <li>if we sell some or all of our business or assets, we may disclose your Personal Information to the prospective seller or buyer of such business or assets, and if the transaction closes, then your Personal Information may be transferred to the buyer.</li>
                        </ul>
                        </span>
                        <p className="h6 mb-0 text-uppercase mt-4">Transfers outside the UK and EEA</p>
                        <p className="mt-1 cache-haptik">
                        We use the current Standard Contractual Clauses adopted by the European Commission (SCCs) as the legal mechanism to transfer Personal Information from the UK to the US.
                        <br/><br/>
                        As part of the services offered to you through the Website, the Personal Information you provide to us may be transferred to and stored in the United States and in certain circumstances, other countries outside of the UK and European Economic Area (EEA). By way of example, a transfer of your Personal Information may happen if one of our service providers is located in a country outside of the UK or EEA. 
                        <br/><br/>
                        We will only transfer Personal Information outside of the UK or EEA where it is compliant with data protection legislation and the means of transfer provides adequate safeguards in relation to your Personal Information, for example, by way of data transfer agreement incorporating the SCCs.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Use of cookies</p>
                        <p className="mt-1 cache-haptik">The Website uses "cookies" to help you personalise your online experience. A cookie is a text based file that is placed by us or our remarketing partners to be stored on your device by a web page or application. Our cookies do not run programs or deliver viruses to your computer. Cookies are uniquely assigned to your computer, and can only be read by a web application within the domain that issued the cookie to your computer.
                        <br/><br/>
                        We may place the following cookies:
                        </p>
                        <span className="cache-haptik">
                        <ul>
                            <li>Strictly necessary cookies. These are cookies that are required for the operation of the Website. They include, for example, cookies that enable you to log into secure areas of our Website.</li>
                            <li>Analytical/performance cookies. These allow us to recognise and count the number of visitors and to see how visitors move around the Website when they are using it. This helps us to improve the way our Website works, for example, by ensuring that users are finding what they are looking for easily.</li>
                            <li>Functionality cookies. These are used to recognise you when you return to the Website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>
                            <li>Targeting cookies. These cookies record your visit to the Website, the pages you have visited and the links you have followed. We will use this information to make our Website more relevant to your interests and for remarketing as described below.</li>
                        </ul>
                        If you want to check or change what types of cookies you accept, this can usually be done within your browser settings. Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control.
                        </span>
                        <p className="h6 mb-0 text-uppercase mt-4">How to reject cookies</p>
                        <p className="mt-1 cache-haptik">
                        If you do not want to receive cookies, you can usually choose to opt-out by modifying your browser settings to decline them. If you choose to decline cookies, you may not be able to access all or parts of, or to fully experience the interactive features of, CACHE services or web pages you visit.
                        <br/><br/>
                        For more information generally on cookies, including how to disable them, please refer to <a href="https://www.aboutcookies.org">aboutcookies.org</a>. This site also provides details on how to delete cookies from your computer.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Data security and integrity</p>
                        <p className="mt-1 cache-haptik">
                        CACHE is committed to taking reasonable and appropriate steps to protect the Personal Information that we hold from misuse, loss, or unauthorised access. We do this by using a range of technical and organisational measures including measures to deal with any suspected data breach. If you suspect any misuse or loss or unauthorised access to your personal information, please let us know immediately by emailing <a href="mailto:support@cache.city">support@cache.city</a>.
                        <br/><br/>
                        The Website is designed to secure the Personal Information you provide on computer servers in a controlled, secure environment, with protections from unauthorised access, use or disclosure. When Personal Information is transmitted to other websites, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol.
                        <br/><br/>
                        However, while we do our best to protect your Personal Information, we cannot guarantee the security of all data which is transmitted to the Website or to another website via the internet or similar connection. If we have given you (or you have chosen) a password to access certain areas of the Website, please keep this password safe.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Your rights</p>
                        <p className="mt-1 cache-haptik">
                        Under GDPR, you have various rights in relation to your Personal Information, such as the rights of access, rectification, restriction, objection, portability, and erasure. Please note that these rights are subject to certain limitations set forth in applicable law.
                        <br/><br/>
                        To exercise these rights, please email <a href="mailto:support@cache.city">support@cache.city</a>. We will seek to deal with your request without undue delay, and in any event in accordance with the requirements of applicable laws. Please note that we may keep a record of your communications to help us resolve any issues you raise.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Third party advertising</p>
                        <p className="mt-1 cache-haptik">
                        We may use remarketing services to advertise our products to you on third party websites after you have visited our Website.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Links to third party websites</p>
                        <p className="mt-1 cache-haptik">
                        Our Website includes links to other websites whose privacy practices are not under CACHE's control. If you submit Personal Information to any of those websites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy on any website that you visit.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Children</p>
                        <p className="mt-1 cache-haptik">
                        We do not intend to solicit or collect Personal Information from anyone under the age of 18. If you are under 18, do not enter information on this Website or engage our services. If you believe a child or yours under the age of 18 has entered Personal Information, please contact us to have the data removed and terminate the child's account.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Changes to this policy</p>
                        <p className="mt-1 cache-haptik">
                        We will occasionally update this Policy to reflect company and customer feedback. We encourage you to periodically review this Policy to be informed of how we are protecting your Personal Information.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Contact information</p>
                        <p className="mt-1 cache-haptik">
                        Please email us via <a href="mailto:support@cache.city">support@cache.city</a>, or write to us at: 
                        <br/><br/>
                        Cache Holdings Ltd, 20-22 Wenlock Road, London, England, N1 7GU
                        <br/><br/>
                        For more information, please review our <Link to="/terms">Terms & Conditions</Link>.
                        </p>
                    </Col>
                </Row>
            </Container>
        </Container>
    );

}

export default Privacy;