import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MoralisProvider } from "react-moralis";
import 'bootstrap/dist/css/bootstrap.min.css';
import TagManager from 'react-gtm-module'

const root = ReactDOM.createRoot(document.getElementById("root"));

const tagManagerArgs = {
  gtmId: 'GTM-KDKL6ZX'
}

TagManager.initialize(tagManagerArgs)

root.render(
  <React.StrictMode>
    <MoralisProvider appId="HmzAz6hClVvqPsWB8UivSRDTadKDJ5HmCBntEuno" serverUrl="https://24xloi5ufrn5.usemoralis.com:2053/server">
        <App />
    </MoralisProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
