import { Container, Row, Col } from "react-bootstrap";

function FAQs(){


    return (
        <Container fluid className="p-0 m-0 w-100">
            <Container className="mt-5">
                <Row>
                    <Col className="p-5">
                        <p className="mt-3 h6 text-uppercase">WHAT is CACHE mixtapes and how does it work ?</p>
                        <p className="mt-1 cache-haptik">CACHE mixtapes is about making music sharing personal again. To do this we are collaborating with DJs, musicians, and a variety of curators to create highly personalised and extremely rare musical experiences.
                            <br/><br/>
                            Each mixtape we create is a custom made piece of 3D digital artwork designed by the DJ/Artist/Musician themselves. The tapes are minted on the Ethereum blockchain as NFT collectibles and once purchased will give the user access to the music experience. This experience will be private to the owner/s of the mixtape NFT. Think of the NFT as an exclusive pass, a pass only the buyer will have.  Our NFT mixtapes may also come with other real world utilities which will vary on a case by case basis.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Do I get to download the music experience?</p>
                        <p className="mt-1 cache-haptik">The exclusive musical experience, whatever form it may take, is not downloadable. This ensures it retains its value by maintaining its scarcity. If every owner of the NFT was able to download the experience then it would cease to be exclusive and would therefore lose its value.</p>
                        <p className="h6 mb-0 text-uppercase mt-4">Can I resell the NFT?</p>
                        <p className="mt-1 cache-haptik">At launch, the NFTs will not be re-sellable. However, we plan to launch a full CACHE marketplace for the future re-sale and trading of the NFTs and we are also looking to implement integrations with other marketplaces such as OpenSea in the future.</p>
                        <p className="h6 mb-0 text-uppercase mt-4">How do I claim my real world utilities?</p>
                        <p className="mt-1 cache-haptik">Once you have purchased your NFT you will be put in touch with the artist or their management directly to claim any real world utilities.</p>

                    </Col>
                </Row>
            </Container>
        </Container>
    );

}

export default FAQs;