import { useState, useEffect } from "react";
import { Magic } from "magic-sdk";

function useMagic(){
    const [web3auth, setWeb3auth] = useState();
    
    useEffect(() => {
      const init = async () => {
        try {
        const web3auth = new Magic('pk_live_DCF12CF742F66AD0');
        setWeb3auth(web3auth);
        } catch (error) {
            console.error(error);
        }
      }
      init();
    },[]);

    return web3auth
}

export default useMagic;