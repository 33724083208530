import { useMoralis } from "react-moralis";
import { useState, useEffect, useContext } from "react";
import { Button, Modal, Form, Col, Row, Image, CloseButton, Spinner } from 'react-bootstrap';
import { 
    MixCopy,
    CacheRulesIMG,
    VadimIMG,
    YodaIMG,
    mkIMG,
    shorteeIMG } from '../assets/mixes';
import { useNavigate } from "react-router-dom";
import validator from 'validator';
import { Link } from 'react-router-dom';
import useMagic from './hooks/useMagic';
import { AuthContext } from './contexts/AuthContext';

function Auth(props){
    const { authError } = useMoralis();
    const navigate = useNavigate();
    const {REACT_APP_HUBSPOT_KEY} = process.env;
  
    const [ email, setEmail ] = useState("");
    const [emailError, setEmailError] = useState("Please Enter a valid Email");
    const [ artistName, setArtistName ] = useState("");
    const [show, setShow] = useState(false);
    const [useDefaultText, setUseDefaultText] = useState(true);
    const defaultText = "To celebrate the launch of CACHE MIXTAPES we have curated a free 90s hiphop mix. Unlike our future mixtapes this is not an NFT and is not available to be purchased or owned.";

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [image, setImage] = useState(CacheRulesIMG);

    const [pageURL, setPageURL] = useState(0);
    useEffect(() => {
        setPageURL(window.location.href);
    },[]);

    const magic = useMagic();
    const [isAuthenticated, setIsAuthenticated] = useContext(AuthContext);
    const [isAuthenticating, setIsAuthenticating] = useState(false);

    useEffect(() => {
        if (props.mixName){
            switch(props.mixName){
                default :
                    setImage(CacheRulesIMG);
                    setUseDefaultText(true);
                    break;
                case 'CacheRules': 
                    setImage(CacheRulesIMG);
                    setUseDefaultText(true);
                    break;
                case 'Vadim':
                    setImage(VadimIMG);
                    setArtistName(MixCopy.Vadim.artist);
                    setUseDefaultText(false);
                    break;
                case 'Yoda':
                    setImage(YodaIMG);
                    setArtistName(MixCopy.Yoda.artist);
                    setUseDefaultText(false);
                    break;
                case 'mk':
                    setImage(mkIMG);
                    setArtistName(MixCopy.mk.artist);
                    setUseDefaultText(false);
                    break;
                case 'shortee':
                    setImage(shorteeIMG);
                    setArtistName(MixCopy.shortee.artist);
                    setUseDefaultText(false);
                    break;
            }
        }
    },[props.mixName]);

    //Magic Authentication
    const handleCustomLogin = async () => {
        setIsAuthenticating(true);
        
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + REACT_APP_HUBSPOT_KEY);
        myHeaders.append("Content-Type", "application/json");

        const pre = {
            "fields": [
              {
                "objectTypeId": "0-1",
                "name": "email",
                "value": email
              }
            ],
            "context": {
              "pageUri": pageURL,
              "pageName": "CACHE"
            },
            "legalConsentOptions": {
              "consent": {
                "consentToProcess": true,
                "text": "I agree to allow CACHE to store and process my personal data.",
                "communications": [
                  {
                    "value": true,
                    "subscriptionTypeId": 999,
                    "text": "I agree to receive marketing communications from CACHE."
                  }
                ]
              }
            }
        }
        
        const raw = JSON.stringify(pre);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        fetch("https://api.hsforms.com/submissions/v3/integration/submit/25640496/c24f17b9-665b-4db6-b718-bf1400adc7de", requestOptions)
            .then(response => response.text())
            .then(result => result)
            .catch(error => console.log('error', error));

        if (magic){
            try {
                await magic.auth.loginWithMagicLink({ email: email, redirectURI: pageURL });
                setIsAuthenticating(false);
                setIsAuthenticated(true);
                handleClose();
                if (props.navTo){
                    navigate(props.navTo);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const validateEmail = (e) => {      
        if (validator.isEmail(e)) {
          setEmailError('');
        } else {
          setEmailError('Please enter a valid Email!');
        }
    }
    // ON enter thing : onKeyDown={e => e.key === 'Enter' && !emailError && handleCustomLogin()}
    return (
        <>
        <span className="m-0 p-0" onClick={handleShow}>{ props.children }</span>
  
        <Modal show={show} onHide={handleClose} centered size="lg" className="rounded-0">
          <Modal.Body className="">
            <CloseButton onClick={handleClose} />
            <Row className="px-2 pt-2 pb-3">
                <Col lg>
                    <Image src={image} fluid />
                </Col>
                <Col className="mt-3" lg>
                <Form id="signup">
                    <p className="cache-haptik">{useDefaultText ? defaultText : "To enable notifications for all future mixtape drops and learn more about the " + artistName + " mixtape, simply sign up or login using your email address below."}</p>
                    {useDefaultText ? <Form.Label htmlFor="inputEmail"><p className="p-0 m-0">Enter your email address to access the mix:</p></Form.Label> 
                        : <Form.Label htmlFor="inputEmail"><p  className="p-0 m-0">Enter your email address to view details:</p></Form.Label>}
                    <Form.Control
                        tabIndex="0"
                        type="email"
                        id="inputEmail"
                        aria-describedby="emailInputBlock"
                        onChange={(e) => {
                            validateEmail(e.target.value);
                            setEmail(e.target.value);
                        }}
                    />

                    {authError && (
                    <>{console.log(JSON.stringify(authError.message))}</>
                    )}

                    <Button variant="dark" className="text-uppercase w-100 border-0 rounded-0 fs-7 mt-4" onClick={handleCustomLogin} disabled={emailError || isAuthenticating}>
                        {isAuthenticating ?
                            <Spinner animation="border" variant="light" size="sm" />
                        : !emailError ?
                            <>Signup/login</>
                        :
                            <>{emailError}</>
                        }
                    </Button>
                    <Form.Text className="text-muted">
                        By signing up, you are agreeing to our <Link to="/terms" className="text-muted">Terms and Conditions</Link>.
                    </Form.Text>
                </Form>
                </Col>
            </Row>
          </Modal.Body>
        </Modal>
        </>
        
    );
}

export default Auth;