import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap';
import { 
    CacheRulesVID,
    VadimVID,
    YodaVID,
    mkVID,
    shorteeVID,
    Placeholder,
    CacheRulesIMG,
    VadimIMG,
    YodaIMG,
    mkIMG,
    shorteeIMG,
    loaiVID,
    loaiIMG
    } from '../assets/mixes';
import { LinkContainer } from 'react-router-bootstrap';
import Auth from './Auth';
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import HoverVideoPlayer from 'react-hover-video-player';
import { useMediaQuery } from 'react-responsive';
import { AuthContext } from './contexts/AuthContext'


function Wrapper(props) {
    if (props.isAuthed) {
      return <Link to={props.linkTo}>{props.children}</Link>;
    }
    return <Auth mixName={props.mixName} navTo={props.linkTo}>{props.children}</Auth>;
}

function AuctionGallery(){
    //const { isAuthenticated } = useMoralis();
    const [isAuthenticated, setIsAuthenticated] = useContext(AuthContext);
    const [mixName, setMixName] = useState();
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
    const fullWideClass = "ps-5 pe-5";
    const mobileClass = "";

    return(
        <Container fluid className={isMobile ? mobileClass : fullWideClass}>
            <Row>
                <Container className="custom-head-auction container d-flex justify-content-center align-items-center">
                    <p className="text-center text-uppercase h5 head-mw">CACHE mixtapes is about making music sharing personal again. To do this we are collaborating with DJs, musicians, and a variety of curators to create highly personalised and extremely rare musical experiences. Find out more <Link to="/FAQs" className="cache-font">here.</Link></p>
                </Container>
            </Row>
            <Row>
                <Col>
                    <Card className="auction-card mx-auto mb-5">
                            <Wrapper isAuthed={isAuthenticated} linkTo="/mixes/cache-rules-mix" mixName={mixName}>
                            <HoverVideoPlayer
                                videoSrc={CacheRulesVID}
                                // We should display an image over the video while it is paused
                                pausedOverlay={
                                    <Image fluid
                                    src={CacheRulesIMG}
                                    alt="CACHE RULES MIX"/>}
                                onHoverStart={() => {setMixName('CacheRules')}}
                                />
                            </Wrapper>
                        <Card.Body>
                            <Card.Title>CACHE<br/>CACHE RULES MIXTAPE</Card.Title>
                            <Card.Text className="fs-8"> 
                                {isAuthenticated ?
                                    <LinkContainer to='/mixes/cache-rules-mix'>
                                        <Button className="button-link p-0 fs-8">Listen Now</Button>
                                    </LinkContainer>
                                    :
                                    <Button className="button-link p-0 fs-8" onClick={() => setMixName('CacheRules')}><Auth navTo='/mixes/cache-rules-mix' mixName={mixName}>Signup to listen</Auth></Button>
                                }
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="auction-card mx-auto mb-5">
                            <Wrapper isAuthed={isAuthenticated} linkTo="/mixes/loai" mixName={mixName}>
                            <HoverVideoPlayer
                                videoSrc={loaiVID}
                                // We should display an image over the video while it is paused
                                pausedOverlay={
                                    <Image fluid
                                    src={loaiIMG}
                                    alt="DJ Yoda"/>
                                }
                                onHoverStart={() => {setMixName('Yoda')}}
                            />
                            </Wrapper>
                        <Card.Body>
                        <Card.Title>LOAI<br/>Culture Defined Mixtape</Card.Title>
                        <Card.Text className="fs-8"> 
                        1/41 &nbsp;&nbsp;&nbsp;&nbsp;{!isAuthenticated ? <Button className="button-link p-0 fs-8" onClick={() => setMixName('loai')}><Auth mixName={mixName} navTo='/mixes/loai'>Sign Up To View Details</Auth></Button>
                                                                                            :
                                                                                            <LinkContainer to='/mixes/loai'>
                                                                                                <Button className="button-link p-0 fs-8">View Details</Button>
                                                                                            </LinkContainer>
                                                                                         }
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="auction-card mx-auto mb-5">
                            <Wrapper isAuthed={isAuthenticated} linkTo="/mixes/dj-vadim" mixName={mixName}>
                            <HoverVideoPlayer
                                videoSrc={VadimVID}
                                // We should display an image over the video while it is paused
                                pausedOverlay={
                                    <Image fluid
                                    src={VadimIMG}
                                    alt="DJ Vadim"/>    
                            }
                            onHoverStart={() => {setMixName('Vadim')}}
                            />
                            </Wrapper>
                        <Card.Body>
                        <Card.Title>DJ Vadim<br/>90s Hip Hop Mixtape</Card.Title>
                        <Card.Text className="fs-8"> 
                        1/1 &nbsp;&nbsp;&nbsp;&nbsp;{!isAuthenticated ? <Button className="button-link p-0 fs-8" onClick={() => setMixName('Vadim')}><Auth mixName={mixName} navTo='/mixes/dj-vadim'>Sign Up To View Details</Auth></Button>
                                                                                            :
                                                                                            <LinkContainer to='/mixes/dj-vadim'>
                                                                                                <Button className="button-link p-0 fs-8">View Details</Button>
                                                                                            </LinkContainer>
                                                                                         }                                                                       
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="auction-card mx-auto mb-5">
                            <Wrapper isAuthed={isAuthenticated} linkTo="/mixes/shortee-blitz" mixName={mixName}>
                            <HoverVideoPlayer
                                videoSrc={shorteeVID}
                                // We should display an image over the video while it is paused
                                pausedOverlay={
                                    <Image fluid
                                    src={shorteeIMG}
                                    alt="Shortee Blitz"/>
                                }
                                onHoverStart={() => {setMixName('shortee')}}
                            />
                            </Wrapper>
                        <Card.Body>
                        <Card.Title>shortee blitz<br/>The Blitz Power Mix</Card.Title>
                        <Card.Text className="fs-8"> 
                        1/1 &nbsp;&nbsp;&nbsp;&nbsp;{!isAuthenticated ? <Button className="button-link p-0 fs-8" onClick={() => setMixName('shortee')}><Auth mixName={mixName} navTo='/mixes/shortee-blitz'>Sign Up To View Details</Auth></Button>
                                                                                            :
                                                                                            <LinkContainer to='/mixes/shortee-blitz'>
                                                                                                <Button className="button-link p-0 fs-8">View Details</Button>
                                                                                            </LinkContainer>
                                                                                         }
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card className="auction-card mx-auto mb-5">
                            <Wrapper isAuthed={isAuthenticated} linkTo="/mixes/dj-mk" mixName={mixName}>
                            <HoverVideoPlayer
                                videoSrc={mkVID}
                                // We should display an image over the video while it is paused
                                pausedOverlay={
                                    <Image fluid
                                    src={mkIMG}
                                    alt="DJ MK"/>
                                }
                                onHoverStart={() => {setMixName('mk')}}
                            />
                            </Wrapper>
                        <Card.Body>
                        <Card.Title>DJ MK<br/>1994 mixtape</Card.Title>
                        <Card.Text className="fs-8"> 
                        1/1 &nbsp;&nbsp;&nbsp;&nbsp;{!isAuthenticated ? <Button className="button-link p-0 fs-8" onClick={() => setMixName('mk')}><Auth navTo='/mixes/dj-mk' mixName={mixName}>Sign Up To View Details</Auth></Button>
                                                                                            :
                                                                                            <LinkContainer to='/mixes/dj-mk'>
                                                                                                <Button className="button-link p-0 fs-8">View Details</Button>
                                                                                            </LinkContainer>
                                                                                         }
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col> 
                    <Card className="auction-card mx-auto mb-5">
                            <Wrapper isAuthed={isAuthenticated} linkTo="/mixes/dj-yoda" mixName={mixName}>
                            <HoverVideoPlayer
                                videoSrc={YodaVID}
                                // We should display an image over the video while it is paused
                                pausedOverlay={
                                    <Image fluid
                                    src={YodaIMG}
                                    alt="DJ Yoda"/>
                                }
                                onHoverStart={() => {setMixName('Yoda')}}
                            />
                            </Wrapper>
                        <Card.Body>
                        <Card.Title>DJ Yoda<br/>90s Hip Hop Mixtape</Card.Title>
                        <Card.Text className="fs-8"> 
                        1/1 &nbsp;&nbsp;&nbsp;&nbsp;{!isAuthenticated ? <Button className="button-link p-0 fs-8" onClick={() => setMixName('Yoda')}><Auth mixName={mixName} navTo='/mixes/dj-yoda'>Sign Up To View Details</Auth></Button>
                                                                                            :
                                                                                            <LinkContainer to='/mixes/dj-yoda'>
                                                                                                <Button className="button-link p-0 fs-8">View Details</Button>
                                                                                            </LinkContainer>
                                                                                         }
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col> 
                    <Card className="auction-card mx-auto mb-5">
                        <Card.Img variant="top" src={Placeholder} />
                        <Card.Body>
                        <Card.Title>To Be Announced</Card.Title>
                        <Card.Text className="fs-8"> 
                        1/1 &nbsp;&nbsp;&nbsp;&nbsp; Coming Soon &nbsp;&nbsp;&nbsp;&nbsp;
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    
                    <Card className="auction-card mx-auto mb-5">
                        <Card.Img variant="top" src={Placeholder} />
                        <Card.Body>
                        <Card.Title>To Be Announced</Card.Title>
                        <Card.Text className="fs-8"> 
                        1/1 &nbsp;&nbsp;&nbsp;&nbsp; Coming Soon &nbsp;&nbsp;&nbsp;&nbsp;
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col> 
                    <Card className="auction-card mx-auto mb-5">
                        <Card.Img variant="top" src={Placeholder} />
                        <Card.Body>
                        <Card.Title>To Be Announced</Card.Title>
                        <Card.Text className="fs-8"> 
                        1/1 &nbsp;&nbsp;&nbsp;&nbsp; Coming Soon &nbsp;&nbsp;&nbsp;&nbsp;
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>               
            </Row>
        </Container>
    );
}

export default AuctionGallery;