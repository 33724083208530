import './App.css';
import { useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import AuctionGallery from './components/AuctionGallery';
import Mixes from './pages/Mixes';
import FAQs from './pages/FAQs';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Footer from './components/Footer';
import { AuthContext } from './components/contexts/AuthContext';


function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);

    return (
      <AuthContext.Provider value={[isAuthenticated, setIsAuthenticated]}>
      {!showSpinner ?
      <Container fluid className="p-0 m-0">
        <Router>
          <NavigationBar />
          <Routes>
            <Route path="/" element={<AuctionGallery />}></Route>
            <Route path="/mixes">
                <Route path="cache-rules-mix" element={<Mixes mixName="CacheRules" />}></Route>
                <Route path="dj-vadim" element={<Mixes mixName="Vadim" />}></Route>
                <Route path="dj-yoda" element={<Mixes mixName="Yoda" />}></Route>
                <Route path="dj-mk" element={<Mixes mixName="mk" />}></Route>
                <Route path="shortee-blitz" element={<Mixes mixName="shortee" />}></Route>
                <Route path="loai" element={<Mixes mixName="loai" />}></Route>
            </Route>
            <Route path="/FAQs" element={<FAQs />}></Route>
            <Route path="/terms" element={<Terms />}></Route>
            <Route path="/privacy" element={<Privacy />}></Route>
          </Routes>
          <Footer />
        </Router> 
      </Container>
      :
      <Container fluid className="p-0 m-0">
          <Spinner animation="border" role="status" className="custom-spinner">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
      </Container>
      }
      </AuthContext.Provider>
    );
}

export default App;
