import { Container, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';

function Terms(){


    return (
        <Container fluid className="p-0 m-0 w-100">
            <Container className="mt-5">
                <Row>
                    <Col className="p-5">
                        <p className="mt-3 h6 text-uppercase">Terms and Conditions</p>
                        <p className="mt-1 cache-haptik">LAST MODIFIED: 7th June 2022
                            <br/><br/>
                            cache.city is a website operated by Cache Holdings Ltd ("CACHE", "we" or "us"). CACHE is registered in England and Wales under company number 13841639 and its registered office is 20-22 Wenlock Road, London, England, N1 7GU.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Agreement between you and CACHE</p>
                        <p className="mt-1 cache-haptik">
                        These general Terms and Conditions ("Terms") are an agreement ("Agreement") between you and CACHE and cover your use of the information, software, products and services made available through cache.city (the "Website"). By using the Website, you agree to be bound by these Terms, as well as our [Privacy Policy](LINK).
                        <br/><br/>
                        This Agreement governs any use of the Website as a guest, registered user, buyer, seller or otherwise.
                        <br/><br/>
                        You are responsible for making all arrangements necessary for you to have access to the Website and for ensuring any contact details you provide us with are correct and up to date. You are also responsible for ensuring that all persons who access the Website through your internet connection are aware of these Terms and that they comply with them.
                        <br/><br/>
                        The Website is offered to you conditional upon your acceptance of these Terms and any notices contained in these Terms and the Website itself. Please read these Terms carefully before you start using the Website. By using the Website, you agree to these Terms.
                        <br/><br/>
                        You must be the legal age of majority in your country of residence in order to use the Website. In no event is use of the Website permitted by those under the age of 18.
                        <br/><br/>
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Data protection</p>
                        <p className="mt-1 cache-haptik">Any personal information you provide to us via the Website is governed by our <Link to="/privacy" className="cache-haptik">Privacy Policy</Link>, which is incorporated into these Terms.</p>
                        <p className="h6 mb-0 text-uppercase mt-4">Intellectual propery rights</p>
                        <span className="mt-1 cache-haptik">
                        For the purposes of these Terms, "Intellectual Property Rights" means any and all intellectual property rights, whether registered or unregistered, including but not limited to any patents, trademarks, domain names, URLs, design rights, copyright, software rights, database rights, music rights, performance rights, rights in and to business names, product names and logos, processes, trade secrets, confidential information and any similar rights in any jurisdiction.
                        <br/><br/>
                        CACHE shall retain and be assigned all rights, title, interest and Intellectual Property Rights in relation to the 3D models, images, music, performances and all other content found on the Website (the "Content") unless otherwise stated.
                        <br/><br/>
                        Except as otherwise provided in these Terms, you must not:
                        <br/>
                        <ul>
                            <li>reproduce, modify, translate or create derivative works of any Content;</li>
                            <li>sell, licence, sublicense, rent, lease, distribute, copy, publicly display, publish, adapt or edit any Content; or</li>
                            <li>circumvent or disable any security or technical features of the Content.</li>
                        </ul>
                        The music and performances published on this website may be the copyright of their respective owners.
                        <br/><br/>
                        The names of any artists, companies or products used or mentioned on the Website may be the trademarks of their respective owners.
                        <br/><br/>
                        All Content is Copyright © CACHE and/or its suppliers, affiliates, partners and copyright owners. All rights reserved.
                        </span>
                        <p className="h6 mb-0 text-uppercase mt-4">No unlawful or prohibited use</p>
                        <p className="mt-1 cache-haptik">
                        As a condition of your use of the Website, you will not use the Website for any purpose that is unlawful or prohibited by these Terms. You will not use the Website in any manner which could damage, disable, overburden or impair the Website, or interfere with any other party’s use and enjoyment of the Website. You will neither obtain nor attempt to obtain any materials or information through any means not intentionally provided for on the Website.
                        <br/><br/>
                        The Content is protected by copyright and all other applicable Intellectual Property Rights set out above. Content is for your personal use only and not for resale, unless otherwise specified. Your use of the Website does not entitle you to resell any Content from the Website, unless otherwise specified. For the avoidance of doubt, your use of the Website constitutes your acceptance of these Terms and your promise that you will not resell or otherwise attempt to commercially benefit from the Content without our express written consent.
                        <br/><br/>
                        Our status (and those of any identified contributors) as the authors of material on the Website must always be acknowledged and you must not use the material in a derogatory manner. If you print, copy or download any part of the Website in breach of these Terms, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Acceptable use of communications services</p>
                        <span className="mt-1 cache-haptik">
                        The Website may contain or link to services such as bulletin board services, chat areas, news groups, forums, communities, personal web pages, social media channels and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, "Communication Services"). You agree to use the Communication Services only to post, send and receive messages and material that are proper and related to the particular Communication Service.
                        <br/><br/>
                        You agree that when using a Communication Service, you will not:
                        <br/>
                        <ul>
                            <li>defame, abuse, harass, stalk, threaten or otherwise breach the legal rights (such as rights of privacy and publicity) of others;</li>
                            <li>publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information;</li>
                            <li>upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control these rights or have received all necessary consents;</li>
                            <li>upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another's computer;</li>
                            <li>advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages;</li>
                            <li>conduct or forward surveys, contests, pyramid schemes or chain letters;</li>
                            <li>download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner;</li>
                            <li>falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;</li>
                            <li>restrict or inhibit any other user from using and enjoying the Communication Services;</li>
                            <li>breach any code of conduct or other guidelines which may be applicable for any particular Communication Service;</li>
                            <li>harvest or otherwise collect information about others, including email addresses, without their consent; or</li>
                            <li>breach any applicable laws or regulations.</li>
                        </ul>
                        We reserve the right, in our own discretion, to review and remove materials posted to a Communication Service, in whole or in part. CACHE reserves the right to terminate your access to any or all of the Communication Services at any time without notice if we reasonably believe that you have breached these Terms or are misusing the Communication Services in any way.
                        <br/><br/>
                        Always use caution when giving out any personally identifying information about yourself, your family, your friends and your connections in any Communication Service. We do not control or endorse the content, messages or information found in any Communication Service and, therefore, we specifically disclaim any liability with regard to the Communication Services and any actions resulting from your participation in any Communication Service. Managers and hosts are not our authorised spokespersons and their views do not necessarily reflect our views.
                        <br/><br/>
                        Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction and/or dissemination. You are responsible for keeping within these limitations if you download the materials.
                        </span>
                        <p className="h6 mb-0 text-uppercase mt-4">Ownership and preservation of your materials</p>
                        <p className="mt-1 cache-haptik">
                        CACHE does not own any of the materials you provide to us (including feedback or suggestions) or post, upload, input or submit to any CACHE website or its associated services (collectively, your "Submissions").
                        <br/><br/>
                        By posting, uploading, inputting, providing or submitting your Submission, you grant CACHE, its affiliated companies and necessary sublicensees permission to use your Submission in connection with the operation of their Internet businesses including, without limitation, the right to copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish your name in connection with your Submission. No compensation will be paid in relation to the use of your Submission. We are under no obligation to post or use any Submission you provide and may remove any Submission at any time at our sole discretion.
                        <br/><br/>
                        By posting, uploading, inputting, providing or submitting your Submission, you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.
                        <br/><br/>
                        We reserve the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, at our discretion.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Links to third party websites</p>
                        <p className="mt-1 cache-haptik">
                        CACHE websites may contain links to third party resources and businesses on the Internet, called here "links" or "Linked Sites." Those links are provided for your convenience to help you identify and locate other Internet resources that may be of interest to you. CACHE does not sponsor and is not legally associated with any third party Linked Sites. CACHE is not legally authorised to use any trade name, registered trademark, logo or copyrighted material that may appear in the link.
                        <br/><br/>
                        CACHE does not control, endorse or monitor the contents of any Linked Site. That includes, without limitation, any further link contained in a Linked Site, and any changes or updates to a Linked Site. CACHE is not responsible for streams or for any other form of transmission received from any Linked Site. These Terms do not cover your interaction with Linked Sites. You should carefully review the terms and conditions and privacy policies of any third party sites.
                        <br/><br/>
                        If you use any service provided on a Linked Site, (a) CACHE will not be responsible for any act or omission of the third party, including the third party's access to or use of your customer data and (b) CACHE does not warrant or support any service provided by the third party.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Liability disclaimer</p>
                        <p className="mt-1 cache-haptik">
                        The information, software, products, and services included in or available through the Website may include inaccuracies or errors. Changes, subtractions and additions will be periodically made to the information on the Website. CACHE and/or its suppliers may make improvements and/or changes to the Website at any time. Information and opinions received via the Website should not be relied upon for personal, medical, legal or financial decisions and you should consult an appropriate professional for specific advice tailored to your situation.
                        <br/><br/>
                        Although CACHE will use reasonable endeavours to keep the Website available and the information on the Website reasonably accurate, CACHE and/or its suppliers make no representations about the suitability, reliability, availability, timeliness, and accuracy of the information, software, products, services and related media contained on the Website for any purpose. All such information, software, products, services and related media are provided "as is" without warranty or condition of any kind. CACHE and/or its suppliers disclaim all warranties and conditions with regard to this information, software, products, services and related media, including all implied warranties or conditions of merchantability, fitness for a particular purpose, title and non-infringement.
                        <br/><br/>
                        This section of the Terms does not affect claims relating to death or personal injury caused by negligence and does not limit or exclude any liability for fraudulent misrepresentation.
                        <br/><br/>
                        If you have a concern or need help, please contacting our customer service department via <a href="mailto:support@cache.city">support@cache.city</a>
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">General</p>
                        <p className="mt-1 cache-haptik">
                        You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of this Agreement or your use of the Website.
                        <br/><br/>
                        Our performance of this Agreement is subject to existing laws and legal process. Nothing contained in this Agreement is in derogation of our right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Website or information provided to or gathered by us with respect to such use.
                        <br/><br/>
                        If any part of this Agreement is determined to be invalid or unenforceable under any applicable law, including, but not limited to, the warranty disclaimers and liability limitations set out above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the Agreement will continue in effect.
                        <br/><br/>
                        We may provide you with notices, including those regarding changes to the Terms by email, land-based mail, postings on the Website, or other reasonable means now known or developed in the future.
                        <br/><br/>
                        A printed version of the Agreement, and of any notice given in electronic form, will be admissible in judicial or administrative proceedings based upon or relating to this Agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish of the parties that this Agreement and all related documents have been drawn up in English.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Modifications</p>
                        <p className="mt-1 cache-haptik">
                        We aim to update the Website regularly and can change the content at any time. We will use reasonable endeavours to keep the Website available to you, but if necessary, we may suspend access to the Website, or close it indefinitely. We will not be liable if for any reason the Website is unavailable at any time or for any period.
                        <br/><br/>
                        We may revise these Terms from time to time, and will post the most current version on our website. If a revision meaningfully reduces your rights, we will notify you (by, for example, updating this page). By continuing to use or access the Services after the revisions come into effect, you agree to be bound by the revised Terms.
                        <br/><br/>
                        If you have any questions or concerns, please contact us via <a href="mailto:support@cache.city">support@cache.city</a>
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Entire agreement</p>
                        <p className="mt-1 cache-haptik">
                        Unless otherwise specified, this Agreement constitutes the entire Agreement between you and us with respect to the Website and it supersedes all prior communications and proposals, whether electronic, oral or written, between you and us with respect to the Website.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Assignment</p>
                        <p className="mt-1 cache-haptik">
                        You must not assign or otherwise transfer the Terms, or any right granted under them, without our written consent. We can freely transfer our rights under the Terms.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Waiver</p>
                        <p className="mt-1 cache-haptik">
                        Any failure by us to enforce or exercise any provision of the Terms, or any related right, will not be a waiver of that provision or right. Any rights not expressly granted in this Agreement are reserved. If a provision is found unenforceable, the remaining provisions of the Terms will remain in full effect and an enforceable term will be substituted reflecting our intent as closely as possible.
                        </p>
                        <p className="h6 mb-0 text-uppercase mt-4">Applicable Law</p>
                        <p className="mt-1 cache-haptik">
                        These Terms and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and interpreted in accordance with the laws of England and Wales.
                        </p>
                    </Col>
                </Row>
            </Container>
        </Container>
    );

}

export default Terms;