import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from '../assets/CACHE.svg';
import { LinkContainer } from 'react-router-bootstrap';
//import NativeBalance from './NativeBalance';
import Auth from './Auth';
import useMagic from './hooks/useMagic';
import { useContext } from 'react';
import { AuthContext } from './contexts/AuthContext';

function NavigationBar(){
    //const { logout, isAuthenticated, user } = useMoralis();
    //const { logout, isAuthenticated } = useMoralis();
    const [isAuthenticated, setIsAuthenticated] = useContext(AuthContext);
    const magic = useMagic();

    const logout = (async () => {
        await magic.user.logout();
        setIsAuthenticated(false);
    });

    return(
        <Navbar bg="light" expand="lg" className="cache-font cache-nav py-0">
            <Container fluid>
            <LinkContainer to="/">
            <Navbar.Brand className="py-0">
                <img
                    src={logo}
                    width="70"
                    height="30"
                    className="d-inline-block align-top"
                    alt="CACHE Logo"
                />
            </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end ms-auto">
                {/*
                <NavDropdown title="SHOP NOW" id="basic-nav-dropdown">
                <LinkContainer to="/">
                    <NavDropdown.Item>Mixtapes</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item href="#action/3.2">Skateboards</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Poop Dogg</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#home" className="ms-3 py-nav">Contact Us</Nav.Link>
                <Nav.Link href="#home" className="ms-3 py-nav">Follow Us</Nav.Link>
                <Nav.Link href="#link" className="ms-3 py-nav">Newsletter</Nav.Link>*/}
                <LinkContainer to="/FAQs">
                    <Nav.Link className="ms-3 py-nav">FAQ</Nav.Link>
                </LinkContainer>
                {/*isAuthenticated ? 
                    <NavDropdown align="end" title="MY CACHE" id="basic-nav-dropdown" className="ms-3">
                        {/*<NavDropdown.Item disabled className="custom-disabled cache-haptik">Welcome {user?.get("ethAddress")}</NavDropdown.Item>
                        <NavDropdown.Item disabled className="custom-disabled cache-haptik">Balance: <NativeBalance/></NavDropdown.Item>
                        <NavDropdown.Item disabled className="custom-disabled">Coming Soon!</NavDropdown.Item>
                        <NavDropdown.Item className="custom-disabled" onClick={logout}>Sign Out</NavDropdown.Item>}
                    </NavDropdown>
                    :
                    <Nav.Link className="ms-3 py-nav"><Auth className="fs-8" mixName='' navTo='/mixes/cache-rules-mix'>Signup/Login</Auth></Nav.Link>
            </Navbar.Collapse>*/}
            </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationBar;